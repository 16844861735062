.Login--form iframe {
  width: 106% !important;
  margin: 0px !important;
  left: -5% !important;
}
.Login--form iframe .container,
.Register--Form iframe .container {
  padding: 0 !important;
}
/* Chrome, Safari, Edge, Opera */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

input {
  -webkit-user-select: none;
  user-select: none;
}
